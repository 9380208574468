<template>
  <div>
    <div class="Demo-DOH">
      <header
        class="
          bg-clr
          sticky-top
          flex-d
          c-header c-header-light c-header-fixed c-header-with-subheader
        "
      >
        <div>
          <div class="logo pr-1 mobile-pl-1 mx-2">
            <div>
              <a
                href="https://www.doh.gov.ae/covid-19/Health-Workforce-Management-System"
                target="_blank"
                class="p-1 d-inline-block"
                ><img src="/img/white-logo-doh.svg" class="mw-100 h-auto"
              /></a>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column logo-title linespcad title text-white">
          <span>
            <small
              >Kawader - Smart platform for Abu Dhabi's Healthcare
              Workforce</small
            >
          </span>
          <span>
            <small>كــــوادر- منصة ذكية لإدارة الكوادر الصحية في أبوظبي</small>
          </span>
        </div>
        <div class="d-flex align-items-center right-action">
          <CButton
            name="pqr"
            class="btn-white btn-pill text-primary mb-1 license-eligiblity"
            @click="redirectToPQR()"
          >
            <small>License Eligibility</small>
          </CButton>
        </div>
      </header>
    </div>
    <JobDetail />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import JobDetail from "@/components/JobBoardDetail/jobDetailMobile.vue";
export default {
  components: {
    JobDetail,
  },
  data() {
    return {
      urlFullPath: "",
    };
  },
  created() {
    const {
      fullPath,
      params: { uuid },
    } = this.$route;
    this.urlFullPath = fullPath;
    this.jobBoardDetailAction({ job_uuid: uuid, path: this.urlFullPath });
  },
  methods: {
    ...mapActions(["jobBoardDetailAction"]),
    redirectToPQR() {
      this.$router.push({
        path: "/doh/pqr-check",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Demo-DOH {
  .c-header.c-header-fixed {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .c-header[class*="bg-"] {
    border-color: rgba(0, 0, 21, 0.1);
  }
  .bg-clr {
    background: #0a5e67;
  }
  .container-fluid {
    padding-right: 30px;
    padding-left: 30px;
  }
  .hover-decoration-none:hover {
    text-decoration: none;
  }
  .justify {
    text-align: center;
  }
  @media (max-width: 480px) {
    small {
      font-size: 10px !important;
    }
  }
  @media (min-width: 481px) {
    small {
      font-size: 12.8px !important;
    }
  }
  @media (max-width: 480px) {
    .license-eligiblity {
      font-size: 12px !important;
      padding: 6px !important;
      small {
        font-size: 11px !important;
      }
    }
  }
}
</style>
