<template>
  <div class="job-board">
    <CModal
      id="applyjobModal"
      title="Apply Job"
      color="primary"
      centered:false
      @update:show="onShow"
      :show.sync="successModal"
      >{{ modalMessage }}</CModal
    >
    <div class="text-right">
      <router-link to="/public/job-board/2">
        <CButton color="primary" shape="pill">Back</CButton>
      </router-link>
    </div>
    <div class="job-board-detail-component container-fluid">
      <div>
        <CRow class="d-flex justify-content-center">
          <img
            :src="fileSource.logo"
            class="rounded-circle ratio-1"
            style="width: 60%; height: 60%"
            @error="
              $event.target.src = '/img/hospital.png';
              $event.target.style = 'width: 100px; height: 100px';
            "
          />
        </CRow>
      </div>
      <div class="title">
        <p>{{ organisation }}</p>
        <h5 class="label lblspan text-primary text-center">
          Location:
          <span style="color: #00000099">{{ location }}</span>
        </h5>
        <h5 class="label lblspan text-primary text-center" v-if="!isOwnJob">
          <em>Shared</em>
        </h5>
      </div>
      <div class="title mt-5">
        <h5 class="text-center">{{ title }}</h5>
      </div>

      <CRow class="detail-group">
        <CCol lg="6" sm="12" class="detail-group-item">
          <div class="label">Candidate Type</div>
          <div class="value text-left">{{ candidateType }}</div>
        </CCol>
        <CCol lg="6" sm="12" class="detail-group-item">
          <div class="label">Candidate Level</div>
          <div class="value">{{ candidateLevel }}</div>
        </CCol>
      </CRow>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Job Reference</div>
          <div class="value text-left">{{ jobReference }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label">Contract</div>
          <div class="value">{{ contract }}</div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Date Posted</div>
          <div class="value text-left">{{ date_created }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label">Expires on</div>
          <div class="value">{{ expiryDate }}</div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="action-group">
        <div v-if="isOwnJob">
          <button
            v-if="!getSelectedJobBoard.status"
            type="button"
            class="btn btn-primary btn-block mb-3"
            @click="onApplyJob()"
          >
            Apply Now
          </button>
        </div>
        <div v-if="!isOwnJob">
          <button
            v-if="!getSelectedJobBoard.status"
            type="button"
            class="btn btn-primary btn-block mb-3"
            @click="redirectToSharedJobBoard"
          >
            Apply Now
          </button>
        </div>
        <p
          v-if="getSelectedJobBoard.status && getSelectedJobBoard.status.status"
          class="text-center my-3 status"
        >
          {{ getSelectedJobBoard.status.status }}
        </p>
        <social-sharing
          :url="url"
          :title="title"
          :description="jobReference"
          inline-template
        >
          <CDropdown class="nav-dropdown px-4" add-menu-classes="pt-0">
            <template #toggler-content>
              <div class="d-flex align-items-center text">
                <CIcon name="cil-share-alt" class="color-primary" />
              </div>
            </template>
            <CDropdownItem>
              <network network="email">
                <i class="fa fa-envelope"></i> Email
              </network>
            </CDropdownItem>
            <div>
              <CDropdownItem>
                <network network="facebook">
                  <i class="fab fa-facebook-square"></i> Facebook
                </network>
              </CDropdownItem>
            </div>
          </CDropdown>
        </social-sharing>
      </div>
      <div class="text-center">
        <img
          :src="fileSource.image"
          style="width: 100%; max-height: 250px"
          @error="$event.target.src = '/img/job-image.jpg'"
        />
      </div>
      <div class="job-description-content" v-html="jobDescription"></div>
    </div>
  </div>
</template>

<script>
import jobDetail from "./jobDetail.vue";
export default {
  extends: jobDetail,
};
</script>
<style lang="scss" scoped>
.lblspan {
  font-size: 16px !important;
  font-weight: 300 !important;
}

@media all and (max-width: 480px) {
  .spacingHomeBtn {
    margin-top: 40px !important;
  }
}
</style>
